import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
interface UserI extends Keycloak.KeycloakProfile {
  attributes: any;
}
@Injectable({
  providedIn: 'root'
})
export class UserAccessService {

  constructor(
    private keycloakAngular: KeycloakService
  ) {}

  public canAccess(roles: string[]): boolean {
    if (roles) {
      const canAccess: (boolean | "")[] = roles.map(role => role && this.keycloakAngular.isUserInRole(role));
      return canAccess.includes(true);
    } else {
      return false;
    }
  }

  public async canEdit(action: string): Promise<boolean> {
    let itCan = false;
    const p: Partial<UserI> = await this.keycloakAngular.loadUserProfile();
    if (Object.keys(p?.attributes).includes(action)) {
      itCan = true;
    }
    return itCan
  }

}
