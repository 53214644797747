import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilesI } from 'src/app/interfaces/file.interface';
import { TableService } from 'src/app/services/table.service';
import { Subject } from 'rxjs/internal/Subject';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { map } from 'rxjs/internal/operators/map';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { of } from 'rxjs/internal/observable/of';
import { delay } from 'rxjs/internal/operators/delay';
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit, OnDestroy {
  public files: FilesI[] = [];
  public keyUp = new Subject<KeyboardEvent>();
  private imageFile: any = null;
  private unsubscriber = new ReplaySubject();
  constructor(
    private tservice: TableService,
    private keycloakService: KeycloakService
  ) {
    this.serchImage();
  }
  private skip = 0;
  private limit = 5;
  ngOnInit(): void {
    this.tservice.callApi().subscribe((data) => console.log(data));
    this.tservice
      .getImageList()
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ files }) => {
        this.files = files;
      });
  }

  downloadImage(fileName: string) {
    const url = `${window.location.href}api/v1/images/${fileName}`;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `${fileName}`;
        a.click();
      });
  }
  deleteImage(_id: string) {
    if (confirm('Delete image?')) {
      this.tservice.deleteImage(_id).subscribe(({ status }: any) => {
        if (status) {
          window.alert(`Image has been deleted successfully`);
          this.tservice
            .getImageList(this.skip)
            .pipe(takeUntil(this.unsubscriber))
            .subscribe(({ files }) => {
              this.files = files;
            });
        } else {
          window.alert(`Something went wrong, please ask Brayhan`);
        }
      });
    }
  }
  goBack() {
    this.skip -= this.limit;
    if (this.skip <= 0) {
      this.skip = 0;
    }
    this.tservice
      .getImageList(this.skip)
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ files }) => {
        this.files = files;
      });
  }

  goNext() {
    this.skip += this.limit;
    this.tservice
      .getImageList(this.skip)
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ files }) => {
        this.files = files;
        // console.log(this.files);
        if (this.files.length < 4) {
          this.skip -= this.limit;
        }
      });
  }

  serchImage() {
    this.keyUp.pipe(
      takeUntil(this.unsubscriber),
      map((event: any) => event.target.value),
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(
        delay(500),
      )),
    )
    .subscribe(inputText => {
      this.tservice.getImageByName(inputText)
      .pipe(
        takeUntil(this.unsubscriber)
      )
      .subscribe(({ files }) => {
        this.files = files;
      });
    })
  }

  changeListener(imageInput: any) {
    this.imageFile = imageInput.files[0];
  }

  uploadImage(){
    if(this.imageFile !== null) {
      this.tservice.uploadImage(this.imageFile)
      .pipe(
        takeUntil(this.unsubscriber)
      )
      .subscribe(({msg,status}) => {
        if(status) {
          window.alert(`Image has been uploaded successfully`);
          this.imageFile = null;
        } else {
          window.alert(`We had an error, please contact Brayhan`);
          this.imageFile = null;
        }
      });
    }
  }

  logOut() {
    this.keycloakService.logout();
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
