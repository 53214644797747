<div class="container shadow-lg p-3 mb-5 bg-body rounded mt-5">
    <input type="file" class="file-upload" onchange="console.log(event.target.files)">
    <div class="row text-end">
        <h1><i style="cursor:pointer" class="bi bi-box-arrow-right" title="Log Out" (click)="logOut()"></i></h1>
    </div>
    <div class="row height d-flex justify-content-center align-items-center">
        <div class="col-md-6">
            <div class="input-group mb-3 mt-4">
                <input #imageInput (change)="changeListener(imageInput)" class="form-control" id="formFile" type="file" accept="image/*" placeholder="Load Image"/>
                <div class="input-group-append">
                    <button (click)="uploadImage()" class="btn btn-outline-secondary" type="button" id="button-addon" title="Upload Image"><i class="bi bi-upload"></i></button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="mb-3">
                <i class="bi bi-search"></i>
                <input (keyup)="keyUp.next($event)" class="form-control form-input" type="text" placeholder="Search anything..." /></div>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-md-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Size</th>
                        <th scope="col">Createdtime</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="files">
                    <tr *ngFor="let file of files">
                        <th><i class="bi bi-card-image"></i></th>
                        <td>
                            <picture>
                                <a [href]='"/api/v1/images/" + file.name' target="_blank"><img class="img img-thumbnail" [src]='"/api/v1/images/" + file.name' style="height:50px;max-width:50px;max-height:50px" [alt]="file.name"></a>
                            </picture>
                        </td>
                        <td>{{file.name}}</td>
                        <td>{{ file.size / 1000 }} KB</td>
                        <td>{{ file.mtime | date:'dd/MM/YYYY HH:mm' }}</td>
                        <td>
                            <i style="cursor:pointer" class="bi bi-download" (click)="downloadImage(file.name)" title="Download Image"></i>
                            <span class="mx-3"></span>
                            <i style="cursor:pointer" class="bi bi-trash" (click)="deleteImage(file.name)" title="Delete Image"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row pt-5">
        <div class="col-md-12 text-center">
            <button (click)="goBack()" class="btn btn-secondary" type="button">
                <i class="bi bi-arrow-left"></i>
            </button>
            <span class="px-1"></span>
            <button (click)="goNext()" class="btn btn-secondary" type="button">
                <i class="bi bi-arrow-right"></i>
            </button>
        </div>
    </div>
</div>