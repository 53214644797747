import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TableComponent } from './components/table/table.component';
import { XlogAuthGuard } from './guards/xlog-auth.guard';

const routes: Routes = [
  {
      path: '',
      component: TableComponent,
      canActivate: [XlogAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
