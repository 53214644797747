import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { FilesI } from '../interfaces/file.interface';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(private http: HttpClient) { }

  callApi() {
    const url = `${environment.apiserver}/api/v1`;
    return this.http.get(url);
  }

  getImageList(skip: number = 0): Observable<any> {
    const url = `${environment.apiserver}/api/v1/list?skip=${skip}`;
    return this.http.get<{files:FilesI[]}>(url);
  }

  getImageByName(name: string){
    const url = `${environment.apiserver}/api/v1/list/${name}`;
    return this.http.get<{files:FilesI[]}>(url);
  }

  uploadImage(file: any):Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', file);
    formData.append('scope', 'directapp');
    const url = `${environment.apiserver}/api/v1/upload`;
    return this.http.post(url, formData)
  }

  deleteImage(name: string) {
    const url = `${environment.apiserver}/api/v1/list/${name}`;
    return this.http.delete(url);
  }
}
