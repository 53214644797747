import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { UserAccessService } from '../services/user-access.service'
@Injectable({
  providedIn: 'root'
})
export class XlogAuthGuard extends KeycloakAuthGuard {

  constructor(protected router: Router, protected keycloakAngular: KeycloakService, userAccessService: UserAccessService) {
    super(router, keycloakAngular);
  }
  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {

      if (!this.authenticated) {
        this.keycloakAngular.login();
        return;
      }
      if (!this.roles || this.roles.length === 0) {
        resolve(false);
      }

      if (!this.roles.includes('app_management')) {
        this.keycloakAngular.logout();
        return;
      }

      resolve(true);
    });
  }

}
